import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Navbar from "../components/navbar/Navbar";
import CircularLoaderButton from "../uiComponents/CircularLoaderButton";
import MuiOutlinedInput from "../uiComponents/MuiOutlinedInput";
import { login } from "../services/user";
import MuiInputLabel from "../uiComponents/MuiInputLabel";
import MuiTextField from "../uiComponents/MuiTextField";
import { showErrorToast } from "../utils/toastUtils";
import { toast } from "react-toastify";

//css
const subtitle = {
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "31.92px",
  letterSpacing: "0em",
  color: "#000000B2",
  fontFamily: "Segoe Ui",
};

const submitLoginStyles = {
  width: "100%",
  backgroundColor: "#2A41AB",
  height: "48px",
  borderRadius: "10px",
  fontFamily: "Mulish",
  mt: "38px",
  "&:hover": {
    backgroundColor: "#2A41AB",
  },
};

const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Email is required!"),
  password: yup.string().required("Password is required!"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    setLoading(true);
    try {
      const resp = await login(values, onSubmitProps);

      if (resp) {
        localStorage.setItem("adminName", resp?.name);
        localStorage.setItem("adminProfile", resp?.profile_pic);
        localStorage.setItem('adminType',resp?.type)
        localStorage.setItem('adminId',resp?.id)
        toast.success("login successful");
        navigate("/overview", { replace: true });
        onSubmitProps.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
      console.log(error);
      toast.error("Wrong email or password");
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    const isAuth = !!localStorage.getItem("adminName");
    if (isAuth) {
      navigate("/overview");
    }
  }, []);

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "#F7F9FC",
            }}
          >
            <Navbar />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 96px)",
              }}
            >
              <Box
                sx={{
                  width: "570px",
                  height: "450px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 0px 16px 0px #0000000A",
                  boxSizing: "border-box",
                  padding: "30px 40px",
                  borderRadius: "16px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={subtitle}
                  textAlign="center"
                  m="15px"
                >
                  Welcome to admin panel
                </Typography>
                <div className="paragraph" style={{ marginTop: "9%" }}></div>
                <Stack spacing={2}>
                  <div className="email-field">
                    <MuiTextField
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={Boolean(touched.email) && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                  <div className="password-field">
                    <div
                      className="forgot-password"
                      style={{
                        width: "100%",
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        fontFamily="Mulish"
                        color="#043A87"
                        textAlign="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </Typography>
                    </div>
                    <FormControl variant="outlined" fullWidth>
                      <MuiInputLabel htmlFor="outlined-adornment-password">
                        Password
                      </MuiInputLabel>
                      <MuiOutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={
                          Boolean(touched.password) && Boolean(errors.password)
                        }
                        helperText={touched.password && errors.password}
                      />
                    </FormControl>
                  </div>
                </Stack>

                <div className="login-btn" style={{ textAlign: "center" }}>
                  <CircularLoaderButton
                    variant="contained"
                    type="submit"
                    styles={submitLoginStyles}
                    loaderColor="#000"
                    loader={loading}
                  >
                    Login
                  </CircularLoaderButton>
                </div>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
