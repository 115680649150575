import React, { useState } from "react";
import { Typography, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Navbar from "../components/navbar/Navbar";
import { forgotpassword } from "../services/user";
import { showErrorToast } from "../utils/toastUtils";
import CircularLoaderButton from "../uiComponents/CircularLoaderButton";
import MuiTextField from "../uiComponents/MuiTextField";
import { toast } from "react-toastify";

//css
const subtitle = {
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "31.92px",
  letterSpacing: "0em",
  color: "#000000B2",
  fontFamily: "Segoe Ui",
};

const forgotText = {
  padding: "8px 0",
  color: "#000000",
  fontFamily: "Mulish",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "23.4px",
  textAlign: "center",
};

const sendLinkButtonStyle = {
  width: "100%",
  backgroundColor: "#2A41AB",
  height: "48px",
  borderRadius: "10px",
  mt: "38px",
  "&:hover": {
    backgroundColor: "#2A41AB",
  },
};

const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Email is required!"),
});

const initialValuesLogin = {
  email: "",
};

// main component
const ForgotPassword = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values, onSubmitProps) => {
    setLoading(true);
    try {
      const resp = await forgotpassword(values, onSubmitProps);

      if (resp) {
        toast.success(resp.detail);
        navigate("/password-reset/sent", { replace: true });
        onSubmitProps.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
      toast.error("Something went wrong,please try again!");
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "#F7F9FC",
            }}
          >
            <Navbar />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 96px)",
              }}
            >
              <Box
                sx={{
                  width: "570px",
                  height: "450px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 0px 16px 0px #0000000A",
                  boxSizing: "border-box",
                  padding: "30px 40px",
                  borderRadius: "16px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={subtitle}
                  textAlign="center"
                  m="15px"
                >
                  Welcome to admin panel
                </Typography>
                <div className="paragraph" style={{ marginTop: "9%" }}></div>
                <Stack spacing={2}>
                  <Typography sx={forgotText}>
                    Enter your email id linked with your account where we will
                    send you a password reset link.
                  </Typography>
                  <div className="email-field">
                    <MuiTextField
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={Boolean(touched.email) && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                </Stack>
                <div className="login-btn" style={{ textAlign: "center" }}>
                  <CircularLoaderButton
                    variant="contained"
                    type="submit"
                    loader={loading}
                    styles={sendLinkButtonStyle}
                  >
                    Send Link
                  </CircularLoaderButton>
                </div>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
