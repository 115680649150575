import { Box, Button, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonDateFilterPanel from "../components/filter/CommonDateFilterPanel";
import { saveAs } from "file-saver";
import AnalyticsSection from "../components/AnalyticsSection";
import { getAllUsersDetails, getEvaluationReport } from "../services/user";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";
import { toast } from "react-toastify";
import DownloadDailyReport from "../uiComponents/DownloadDailyReport";
//css
const containerStyle = {
  mx: "30px",
  OverviewY: "scroll",
  my: "45px",
  m: "0px 45px 0 45px",
  boxSizing: "border-box",
};

const initialDateFilter = () => {
  return {
    select: "ALL_TIME",
    from_time: null,
    to_time: null,
  };
};

//config
export const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Last 7 Days", value: "THIS_WEEK" },
  { label: "Last 30 Days", value: "THIS_MONTH" },
  { label: "Last 12 Month", value: "THIS_YEAR" },
  { label: "All Time", value: "ALL_TIME" },
];

// main component
const Overview = () => {
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [scoreList, setScoreList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const adminType = localStorage.getItem("adminType");

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      let endDate = dayjs();
      let startDate = dayjs();
      if (value === "THIS_WEEK") {
        startDate = endDate.subtract(7, "day");
      }
      if (value === "THIS_MONTH") {
        startDate = endDate.subtract(1, "month");
      }
      if (value === "THIS_YEAR") {
        startDate = endDate.subtract(1, "year");
      }
      if (value === "ALL_TIME") {
        startDate = null;
        endDate = null;
      }
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: startDate,
        to_time: endDate,
      }));
      return;
    }
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllUserDate = async (dateFilter) => {
    // if (dateFilter?.from_time.isValid() && dateFilter?.to_time.isValid()) {
    const resp = await getAllUsersDetails(
      dateFilter?.from_time,
      dateFilter?.to_time,
      setLoading,
      setError
    );

    console.log(
      !adminType.includes(["Invigilators", "Interviewers"]),
      adminType,
      "admin type"
    );

    const applicantsList =
      adminType !== "Invigilators" && adminType !== "Interviewers"
        ? [
            {
              label: "Total Logins",
              value: resp?.total_registrations ?? "--",
            },
            {
              label: "Data Not Filled",
              filter: "0",
              value: resp?.onzeropage ?? "--",
            },
            {
              label: "Personal Details Filled",
              filter: "1",
              value: resp?.onfirstpage ?? "--",
            },
            {
              label: "Academic Details Filled",
              filter: "2",
              value: resp?.onsecondpage ?? "--",
            },
            {
              label: "Video Submitted",
              filter: "3",
              value: resp?.onthirdpage ?? "--",
            },
            {
              label: "Payment Done",
              filter: "5",
              value: resp?.payment_done ?? "--",
            },
            {
              label: "Interview Done",
              filter: "6",
              value: resp?.interview_done ?? "--",
            },
            {
              label: "Approved By 2+ Evaluators",
              approval: "2",
              value: resp?.users_approved_multiple ?? "--",
              showThumb: "multiThumbUp",
            },
            {
              label: "Approved By 1 Evaluator",
              approval: "1",
              value: resp?.users_approved_single ?? "--",
              showThumb: "singleThumbUp",
            },
            {
              label: "Not Approved By Any Evaluator",
              approval: "3",
              value: resp?.users_disapproved ?? "--",
              showThumb: "multiThumbDown",
            },
            {
              label: "Applicants Not Evaluated",
              approval: "4",
              value: resp?.users_not_invigilated ?? "--",
              showThumb: "singleThumbDown",
            },
            {
              label: "Wrong Applications",
              wrongApplication: "1",
              value: resp?.wrong_applications ?? "--",
              showThumb: "flag",
            },
            {
              label: "Selected Applicants",
              selected: "1",
              value: resp?.selected ?? "--",
            },
          ]
        : adminType === "Invigilators"
        ? [
            {
              label: "Interview Done",
              filter: "6",
              value: resp?.interview_done ?? "--",
            },
            {
              label: "Approved By 2+ Evaluators",
              approval: "2",
              value: resp?.users_approved_multiple ?? "--",
              showThumb: "multiThumbUp",
            },
            {
              label: "Approved By 1 Evaluator",
              approval: "1",
              value: resp?.users_approved_single ?? "--",
              showThumb: "singleThumbUp",
            },
            {
              label: "Not Approved By Any Evaluator",
              approval: "3",
              value: resp?.users_disapproved ?? "--",
              showThumb: "multiThumbDown",
            },
            {
              label: "Applicants Not Evaluated",
              approval: "4",
              value: resp?.users_not_invigilated ?? "--",
              showThumb: "singleThumbDown",
            },
            {
              label: "Wrong Applications",
              wrongApplication: "1",
              value: resp?.wrong_applications ?? "--",
              showThumb: "flag",
            },
            {
              label: "Selected Applicants",
              selected: "1",
              value: resp?.selected ?? "--",
            },
          ]
        : adminType === "Interviewers"
        ? [
            {
              label: "Approved By Multiple Invigilators",
              approval: "2",
              value: resp?.users_approved_multiple ?? "--",
            },
          ]
        : [
            {
              label: "Interview Done",
              filter: "6",
              value: resp?.interview_done ?? "--",
            },
          ];

    setApplicationList(applicantsList);
    setScoreList([
      {
        label: "Mean",
        value: resp?.mean_score ?? "--",
      },
      {
        label: "Median",
        value: resp?.median_score ?? "--",
      },
      {
        label: "Mode",
        value: resp?.mode_Score ?? "--",
      },
    ]);
    setLoading(false);
    // }
  };

  const truncateText = (text, maxLength = 32767) => {
    return text.length > maxLength ? text.substring(0, maxLength) : text;
  };

  // const preprocessData = (data) => {
  //   return data.map((item) => {
  //     const newItem = {};
  //     for (const key in item) {
  //       if (typeof item[key] === "string") {
  //         newItem[key] = truncateText(item[key]);
  //       } else if (typeof item[key] === "object" && item[key] !== null) {
  //         // Check if the value is an object and not null
  //         newItem[key] = JSON.stringify(item[key]); // Convert the object to a string
  //       } else {
  //         newItem[key] = item[key];
  //       }
  //     }
  //     return newItem;
  //   });
  // };

  const handleDailyReport = () => {
    setIsOpen(true);
  };

  const handleReport = async () => {
    try {
      setLoading(true);
      const data = await getEvaluationReport();
      console.log("report_data", data);
      if (data && data.length === 0) {
        toast.error("No data in selected date range!");
        setLoading(false);
        return;
      }

      const processedData = data?.map((item) => {
        return {
          id: item.id,
          user_id: item.user_id,
          feedback_date: item.date_feedback,
          Resilient_Optimism: item.resilient_optimism,
          Diligence_and_Determination: item.diligence_determination,
          Long_term_Passion_Vs_Fickle_Mindedness: item.longterm_passion,
          Focus: item.focus,
          Risk_Tolerance: item.risk_tolerance,
          Internal_Locus_of_Control: item.internal_locus_control,
          Need_for_Achievement: item.achievement_need,
          Resourcefulness: item.resourcefulness,
          Growth_Mindset: item.growth_mindset,
          Experimental_Mindset: item.experimental_mindset,
          Bootstrap_Vs_Funding: item.bootstrap_funding,
          Curiosity: item.curiosity,
          Founder_Product_Fit: item.founder_fit,
          Passion: item.passion,
          Product_Market_Fit: item.product_market_fit,
          Overall_impression_and_recommendation: item.overall_impression,
          Final_recommendation: item.final_recommendation,
          status: item.status,
          user_first_name: item.user_first_name,
          user_last_name: item.user_last_name,
          admin_name: item.admin_name,
        };
      });

      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(processedData);
      console.log("data.xlsx2", worksheet);
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      console.log("data.xlsx1");
      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Save the blob as an Excel file
      console.log(blob, "data.xlsx");
      saveAs(blob, "applicants-list.xlsx");
      setLoading(false);
    } catch (error) {
      console.error("Error downloading the file", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUserDate(dateFilter);
  }, [dateFilter]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "150px" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: "center", marginTop: "100px" }}>Error</div>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#f7f9fc",
        paddingBottom: "50px",
      }}
    >
      <DownloadDailyReport
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        setLoading={setLoading}
      />
      <Box sx={containerStyle}>
        <Stack py={"20px"} width={"100%"} alignItems={"flex-end"}>
          <Box mb="10px">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2A41AB",
                "&:hover": { backgroundColor: "#2A41AB" },
                textTransform: "none",
              }}
              onClick={handleDailyReport}
            >
              Download Daily Report
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2A41AB",
                "&:hover": { backgroundColor: "#2A41AB" },
                textTransform: "none",
                marginBottom: "5px",
                marginLeft: "5px",
              }}
              startIcon={
                <FaFileExcel size={20} sx={{ textTransform: "capitalize" }} />
              }
              onClick={handleReport}
            >
              Download Evaluation Report
            </Button>
          </Box>
          <CommonDateFilterPanel
            filterSelectOptions={filterSelectOptions}
            dateFilter={dateFilter}
            handleDateFilter={handleDateFilter}
          />
        </Stack>

        <Stack gap={"25px"}>
          <AnalyticsSection
            title={"Total Applicants"}
            sideBackgroundColor={"#2B3F79"}
            analyticsCardWidth={"203px"}
            analyticsCardHeight={"100px"}
            options={applicationList}
            clickable={true}
          />
          <AnalyticsSection
            title={"Score"}
            sideBackgroundColor={"#D1712C"}
            analyticsCardWidth={"365px"}
            analyticsCardHeight={"100px"}
            options={scoreList}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Overview;
