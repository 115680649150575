import { Button, CircularProgress, Backdrop } from "@mui/material";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FaRegFilePdf } from "react-icons/fa";
import { pdf } from "@react-pdf/renderer";
import DownloadInterviewAssessmentPdf from "./pdf/DownloadInterviewAssessmentPdf";
import { toast } from "react-toastify";
import { styled } from "@mui/system";

const DownLoadInterviewQuestionAndAnswers = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const BlurBackdrop = styled(Backdrop)(({ theme }) => ({
    backdropFilter: "blur(5px)",
    zIndex: 1,
    color: "#fff",
  }));

  const handleDownload = async () => {
    setLoading(true);
    try {
      const blob = await pdf(
        <DownloadInterviewAssessmentPdf data={data} />
      ).toBlob();
      saveAs(
        blob,
        `${data?.firstname ?? "assessment-sheet"}-${data?.lastname}`
      );
      setLoading(false);
    } catch (error) {
      if (error) {
        // to show pdf download error modal
        toast.error("Something went wrong please try again!");
      }
    }
  };

  return (
    <>
      <BlurBackdrop open={loading}>
        <CircularProgress color="inherit" />
      </BlurBackdrop>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#2A41AB",
          "&:hover": { backgroundColor: "#2A41AB" },
          textTransform: "none",
        }}
        startIcon={<FaRegFilePdf />}
        onClick={handleDownload}
      >
        Download as PDF
      </Button>
    </>
  );
};

export default DownLoadInterviewQuestionAndAnswers;
