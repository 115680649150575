import React, { useState } from "react";
import {
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import Navbar from "../components/navbar/Navbar";
import MuiInputLabel from "../uiComponents/MuiInputLabel";
import MuiOutlinedInput from "../uiComponents/MuiOutlinedInput";
import { resetPassword } from "../services/user";
import CircularLoaderButton from "../uiComponents/CircularLoaderButton";
import { showErrorToast } from "../utils/toastUtils";
import { toast } from "react-toastify";

const loginSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required!")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character!"
    )
    .min(8, "Password must be at least {8} characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match!")
    .required("Please confirm your password!"),
});

const initialValue = {
  password: "",
  confirmPassword: "",
};

//css
const subtitle = {
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "31.92px",
  letterSpacing: "0em",
  color: "#000000B2",
  fontFamily: "Segoe Ui",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = (name) => {
    setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    const requestData = {
      password: values?.password,
      id: id,
    };
    setLoading(true);
    try {
      const resp = await resetPassword(requestData, onSubmitProps);

      if (resp) {
        toast.success("Password updated successfully");
        navigate("/login", { replace: true });

        onSubmitProps.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
      toast.error("Something went wrong, please try again!");
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValue}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "#F7F9FC",
            }}
          >
            <Navbar />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 96px)",
              }}
            >
              <Box
                sx={{
                  width: "570px",
                  height: "450px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 0px 16px 0px #0000000A",
                  boxSizing: "border-box",
                  padding: "30px 40px",
                  borderRadius: "16px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={subtitle}
                  textAlign="center"
                  m="15px"
                >
                  Welcome to admin panel
                </Typography>
                <div className="paragraph" style={{ marginTop: "9%" }}></div>
                <Stack spacing={5}>
                  <div className="enter-new-password">
                    <FormControl variant="outlined" fullWidth>
                      <MuiInputLabel>Enter New Password</MuiInputLabel>
                      <MuiOutlinedInput
                        id="outlined-adornment-enter-new-password"
                        type={showPassword?.password ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                handleClickShowPassword("password");
                              }}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword.password ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Enter New Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={
                          Boolean(touched.password) && Boolean(errors.password)
                        }
                        helperText={touched.password && errors?.password}
                      />
                    </FormControl>
                  </div>

                  <div className="password-field">
                    <FormControl variant="outlined" fullWidth>
                      <MuiInputLabel htmlFor="outlined-adornment-password">
                        Confirm new password
                      </MuiInputLabel>
                      <MuiOutlinedInput
                        id="outlined-adornment-password"
                        type={
                          showPassword?.confirmPassword ? "text" : "password"
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                handleClickShowPassword("confirmPassword");
                              }}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword?.confirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm new password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        error={
                          Boolean(touched.confirmPassword) &&
                          Boolean(errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors?.confirmPassword
                        }
                      />
                    </FormControl>
                  </div>
                </Stack>

                <div className="login-btn" style={{ textAlign: "center" }}>
                  <CircularLoaderButton
                    variant="contained"
                    loader={loading}
                    sx={{
                      width: "100%",
                      backgroundColor: "#2A41AB",
                      height: "48px",
                      borderRadius: "10px",
                      mt: "38px",
                      "&:hover": {
                        backgroundColor: "#2A41AB",
                      },
                    }}
                    type="submit"
                  >
                    Reset Password
                  </CircularLoaderButton>
                </div>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPassword;
