import React, { useState } from "react";
import { PiFileVideoLight } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import TranscriptionModal from "./TranscriptionModal";
const valueStyle = {
  fontFamily: "Segoe UI",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "left",
  color: "#272727",
};

const VideoAnswer = ({ value, text, index, link, transcription }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TranscriptionModal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        text={text}
        index={index}
        transcription={transcription}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "10px",
          border: "1px solid #D5DAE1",
          borderRadius: "10px",
          // width: "350px",
          marginBottom:"14px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "6px",
            marginBottom: "20px",
          }}
        >
          <div style={valueStyle}>{index}.</div>
          <div style={valueStyle}>{text}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <PiFileVideoLight size={24} />{" "}
            {link ? (
              <a
                style={{
                  ...valueStyle,
                  textDecoration: "none",
                  color: "#5D79C2",
                }}
                href={value}
                rel="noreferrer"
                target="_blank"
              >
                answer{index}.mp4
              </a>
            ) : (
              <div style={valueStyle}>
                {value !== "" &&
                value !== null &&
                value !== undefined &&
                value !== "null" &&
                value !== "undefined"
                  ? value
                  : "--"}
              </div>
            )}
          </div>
          {transcription && (
            <Tooltip title="View Transcription">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  backgroundColor: "#2B4078",
                  borderRadius: "4px",
                  padding: "1px 2px",
                }}
                onClick={() => setIsOpen(true)}
              >
                <IoBookOutline color="#FFFFFF" size={24} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoAnswer;
