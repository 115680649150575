import { Box, Button, Typography } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";

//css
const subtitle = {
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "31.92px",
  letterSpacing: "0em",
  color: "#000000B2",
  fontFamily: "Segoe Ui",
};

// main component
const PasswordResetSentMessage = () => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    navigate("/login", { replace: true });
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F7F9FC",
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100% - 96px)",
        }}
      >
        <Box
          sx={{
            width: "570px",
            height: "363px",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 16px 0px #0000000A",
            boxSizing: "border-box",
            padding: "30px 40px",
            borderRadius: "16px",
          }}
        >
          <Typography variant="h4" sx={subtitle} textAlign="center" m="15px">
            Welcome to admin panel
          </Typography>

          <Typography
            sx={{
              fontFamily: "Mulish",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "27.9px",
              textAlign: "center",
              mt: "10%",
            }}
          >
            "Kindly check your email for a link to reset your password. If
            you're unable to locate it in your main inbox, please ensure to
            inspect your spam or junk folder."
          </Typography>

          <div className="login-btn" style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                // padding: "13px 142px 13px 142px",
                backgroundColor: "#2A41AB",
                height: "48px",
                borderRadius: "10px",
                mt: "38px",
                "&:hover": {
                  backgroundColor: "#2A41AB",
                },
              }}
              onClick={handleClick}
            >
              Back To Login
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordResetSentMessage;
